export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'nl',
    globalInjection: true,
    inheritLocale: true,
    fallbackLocale: 'nl',
    missingWarn: false,
    fallbackWarn: false,
    datetimeFormats: {
        nl: {
            day: {
                day: '2-digit'
            },
            monthShort: {
                month: 'short'
            },
            month: {
                month: 'long', year: 'numeric'
            },
            year: {
                year: 'numeric'
            },
            date: {
                year: 'numeric', month: 'short', day: 'numeric'
            },
            dateShort: {
                month: 'short', day: 'numeric'
            },
            dateTime: {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: false
            }
        },
        en: {
            day: {
                day: '2-digit'
            },
            monthShort: {
                month: 'short'
            },
            month: {
                month: 'long', year: 'numeric'
            },
            year: {
                year: 'numeric'
            },
            date: {
                year: 'numeric', month: 'short', day: 'numeric'
            },
            dateShort: {
                month: 'short', day: 'numeric'
            },
            dateTime: {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: false
            }
        }
    }
}));
